// .is-nav-selected::after {
//   display: none;
// }

.fancybox__thumb {
  opacity: .5;
  &::after { display: none; }
  &:hover { opacity: 1; }

}

.fancybox__thumb:hover,
.is-nav-selected .fancybox__thumb {
  // border-radius: 6px;
  opacity: 1;
}

// .is-nav-selected .fancybox__thumb::after {
//   display: none;
// }

// .info-box {
//   @include z-index(modal);
//   .fancybox-bg { background: none; }
//   .fancybox-slide { padding: 0; }
//   .fancybox-close-small {
//     height: auto;
//     opacity: .6;
//     padding: 1rem;
//     right: 1rem;
//     top: 7.5rem;
//     transform: translateY(-50%);
//     width: auto;
//     .icon {
//       display: block;
//       height: 24px;
//       width: 24px;
//     }
//   }
//   .fancybox-content {
//     background: none;
//     bottom: 0;
//     left: 0;
//     padding: 0;
//     position: fixed;
//     right: 0;
//     top: 0;
//   }

//   @include breakpoint($breakpoint-tablet) {
//     .fancybox-close-small { right: 3rem; }
//   }

//   @include breakpoint($breakpoint-desktop) {
//     .fancybox-close-small {
//       right: 6rem;
//       transition: opacity .2s;
//       top: 10.5rem;
//       .icon {
//         height: 36px;
//         width: 36px;
//       }
//       &:hover { opacity: 1; }
//       &:active { opacity: .4; }
//     }
//   }
// }

// .video-box {
//   // @include breakpoint($breakpoint-mobile-only) {
//     // .fancybox-slide { padding: 0; }
//     // .fancybox-caption { @extend %hidden-mobile-only; }
//   // }
// }
