// Custom fonts used throughout the site.

$default-font: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

@font-face {
  font-family: 'Univers Std 39 Thin Ultra Condensed';
  src: url('data-url:../fonts/Univers-Std-39-Thin-Ultra-Condensed.woff2') format('woff2'),
       url('data-url:../fonts/Univers-Std-39-Thin-Ultra-Condensed.woff') format('woff');
}

$custom-font-univers: 'Univers Std 39 Thin Ultra Condensed', $default-font;
