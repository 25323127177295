.videos {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    border-bottom: 1px solid rgba(255, 255, 255, .125);
    &:last-child { border: 0; }
  }
  .video {
    align-items: center;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    text-decoration: none;
    .thumbnail {
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
                  rgba(0, 0, 0, 0.24) 0px 1px 2px;
      flex-basis: 96px;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      img {
        aspect-ratio: 16 / 9;
        display: block;
        object-fit: cover;
        width: 100%;
      }
      &::after {
        border-radius: 3px;
        bottom: 0;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .125);
        content: '';
        display: block;
        left: 0;
        mix-blend-mode: overlay;
        position: absolute;
        right: 0;
        top: 0;
        @include z-index(front);
      }
    }
    aside {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      min-width: 0; // Allow truncation to activate.
      h4, h5 { @include ellipsis; }
    }
    .icon {
      background-color: $light-purple;
      height: 16px;
      width: 16px;
    }
  }
  @include breakpoint($breakpoint-tablet) {
    ul {
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);
      padding: 3rem;
    }
    li { border: 0; }
    .video {
      flex-direction: column;
      gap: .5rem;
      padding: 0;
      .thumbnail { flex-basis: auto; }
      aside { text-align: center; }
      .icon { @extend %hidden-tablet; }
    }
  }
  @include breakpoint($breakpoint-desktop) {
    ul {
      grid-gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
      padding: 4rem;
    }
    .video {
      justify-content: center;
      position: relative;
      .thumbnail {
        border-radius: 6px;
        transition: box-shadow .125s;
        &::after { border-radius: 6px; }
      }
      aside {
        bottom: 0;
        justify-content: flex-end;
        left: 0;
        opacity: 0;
        padding: 1rem 2rem;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .125s;
        &::before {
          background-image: linear-gradient(to bottom, fade_out($purple, .25), $dark-purple);
          border-radius: 6px;
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          @include z-index(default);
        }
        h4, h5 { @include z-index(front); }
      }
      .icon {
        @extend %shown-desktop;
        background-color: #fff;
        height: 48px;
        opacity: 0;
        position: absolute;
        transition: opacity .125s;
        width: 48px;
        @include z-index(front);
      }
      &:hover {
        .thumbnail {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                      rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
        aside, .icon { opacity: 1; }
      }
    }
  }
  @include breakpoint($breakpoint-widescreen) {
    ul { padding: 6rem 0; }
  }
}
