body {
  background: #2b2b2e url('data-url:../images/bg.png');
  &.fixed { overflow: hidden; }
}

.wrapper {
  min-width: 375px;
  @include breakpoint($breakpoint-widescreen) {
    margin: 0 auto;
    max-width: 1728px;
  }
}
