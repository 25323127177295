@import 'node_modules/normalize.css/normalize';
@import "node_modules/@fancyapps/ui/dist/fancybox/fancybox";

@import 'framework/breakpoints';
@import 'framework/colors';
@import 'framework/ellipsis';
@import 'framework/fonts';
@import 'framework/placeholder-selectors';
@import 'framework/typography';
@import 'framework/z-indexes';

@import 'regions/footer';
@import 'regions/header';
@import 'regions/info';
@import 'regions/layout';

@import 'modules/icons';
@import 'modules/ribbons';
@import 'modules/videos';

@import 'vendor/fancybox-overrides';
