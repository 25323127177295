// Map, function, and mixin for defining and retrieving z-index values via names.

$z-index: (
  back: -1,
  navigation: 0,
  default: 1,
  front: 2,
  modal: 100,
  top: 101
);

@function z-index($layer) {
  @if not map-has-key($z-index, $layer) {
    @warn "No stack level found for `#{$layer}` in $z-index map.";
  }

  @return map-get($z-index, $layer);
}

@mixin z-index($layer) {
  z-index: z-index($layer);
}
