// Colors used throughout the site.

$darker-grey: #59596E;
$dark-grey: #767684;
$grey: #95959e;
$light-grey: #BBBBBF;
$off-white: #EEEEEF;
$purple: #4c489e;
$dark-purple: #332F8F;
$light-purple: #C5C4EB;
