header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6rem 1rem;
  .name-and-title {
    position: relative;
    h1 {
      position: relative;
      @include z-index(top);
    }
  }
  h2 {
    position: absolute;
    top: -1.5rem;
  }
  .info-text {
    @extend h1;
    text-shadow: none;
    a {
      color: $light-purple;
      font-weight: normal;
      text-decoration: none;
      &:hover { color: lighten($purple, 30%); }
    }
  }
  @include breakpoint($breakpoint-tablet) { padding: 7rem 3rem; }
  @include breakpoint($breakpoint-desktop) { padding: 7rem 4rem; }
  @include breakpoint($breakpoint-widescreen) { padding: 10rem 0; }
}
