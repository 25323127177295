footer {
  background: fade_out(#000, .6);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  .stamp { color: $darker-grey; }
  a {
    text-decoration: none;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  @include breakpoint($breakpoint-tablet) {
    flex-direction: row;
    gap: 1.5rem;
    padding: 1.5rem 3rem;
    div { flex-basis: 50%; }
    .copyright { text-align: end; }
    .stamp { text-align: start; }
  }
}
