// Variables and mixin for inlining media queries.

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1366px;
$breakpoint-widescreen: 1920px;
$breakpoint-retina: min-resolution 2dppx;

@mixin breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
