// Placeholder selectors used throughout the site. Extend these to combine display styles into a single location in the production stylesheet.

%hidden { display: none; }
// %shown { display: inline; }
// %inherited { display: inherit; }
// %invisible { visibility: hidden; }
// %visible { visibility: visible; }

@include breakpoint($breakpoint-tablet) {
  %hidden-tablet { display: none; }
}

@include breakpoint($breakpoint-desktop) {
  %shown-desktop { display: initial; }
}
