// Type Scale
//
// 16px - 1rem
// 21px - 1.333rem @ 1.625rem
// 28px - 1.777rem @ 2.125rem
// 38px - 2.369rem @ 2.875rem
// 50px - 3.157rem @ 3.8125rem
// 67px - 4.209rem @ 5.125rem
// 89px - 5.61rem  @ 6.8125rem
// 120px - 7.478rem @ 9.0625rem

html, body { font-size: 16px; }

body {
  color: $grey;
  font-family: $default-font;
  line-height: 1.5rem;
  text-rendering: optimizeSpeed;
  text-size-adjust: none;
  @include breakpoint($breakpoint-desktop) { text-rendering: auto; }
}

// Headings

h1, h2, h3, h4, h5 { margin: 0; }
h2, h5 { font-weight: normal; }

h1 {
  color: #fff;
  font-family: $custom-font-univers;
  font-size: 3.157rem;
  font-weight: 100;
  line-height: 3.8125rem;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(0, 0, 0, .4);
  @include breakpoint($breakpoint-tablet) {
    font-size: 4.209rem;
    line-height: 5.125rem;
  }
  @include breakpoint($breakpoint-desktop) {
    font-size: 5.61rem;
    line-height: 6.8125rem;
    text-rendering: geometricPrecision;
  }
  @include breakpoint($breakpoint-widescreen) {
    font-size: 7.478rem;
    line-height: 9.0625rem;
  }
}

h2 {
  color: $darker-grey;
  font-size: 1.333rem;
  line-height: 1.625rem;
  text-transform: lowercase;
}

h3 {
  color: $off-white;
  @include breakpoint($breakpoint-desktop) {
    font-family: $custom-font-univers;
    font-size: 2.369rem;
    font-weight: 100;
    line-height: 2.875rem;
    font-weight: normal;
    text-transform: uppercase;
  }
  @include breakpoint($breakpoint-widescreen) {
    font-size: 3.157rem;
    line-height: 3.8125rem;
  }
}

h4 {
  color: $off-white;
  font-weight: 500;
}

h5 {
  color: $dark-grey;
  font-size: .875rem;
  @include breakpoint($breakpoint-desktop) {
    color: $light-purple;
    mix-blend-mode: hard-light;
  }
}

// Paragraphs

p { margin: 0 0 1.5rem 0; }

// Links

a {
  color: inherit;
  font-weight: bold;
  transition: color .125s;
  @include breakpoint($breakpoint-desktop) {
    &:active {
      position: relative;
      top: 1px;
    }
  }
}
